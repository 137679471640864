
import {defineComponent, onMounted, ref} from "vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import apiService from "@/core/services/ApiService";

export default defineComponent({
  name: "LoginSurvey",
  props: {
    userId: {
      required: true,
      type: Number,
    },
    regionId: {
      required: true,
      type: Number,
    },
    notShow: {
      type: Boolean,
      required: true,
    },
  },
  emit: ["modal-value"],
  setup: function (props, { emit }) {
    const region = ref([]);
    const welcome_message = ref<string>("");

    /*    const MESSAGE =
        "<h6 class='text-primary'>QUICK START</h6>" +
        "\n" +
        "1. Click “ADD Assessment” to start a new assessment<br>" +
        "2. Click “COMPLETE” to start answering questions<br>" +
        "3. Start answering as many questions as you can, then submit your responses - DONE.<br>" +
        "4. See your results click “DETAILS”<br>" +
        "5. Reset all answers by clicking “RESET” (once you reset your answers are erased permanently and cannot be retrieved. <br>" +
        "<br>" +
        "<h6 class='text-primary'>NOTES</h6>" +
        "6. You can log in and out to continue responding to questions.<br>" +
        "7. You might want to have the right people answer the questions based on their job and expertise.<br>" +
        "8. When you discuss the questions with your team, sometimes you obtain better answers.<br>" +
        "9. The method will provide an overall score for each component that makes it simple to see where you excel (<span class='text-danger'>red</span>, <span class='text-warning'>yellow</span>, <span class='text-success'>green</span>)<br>" +
        "10. The scoring will automatically adjust when the written replies are examined.<br>" +
        "11. Repeat once every year to assess your development.<br>" +
        "12. You will be informed of the 50 best Managed IT Companies Award if your score falls within the top 50.<br>" +
        "13. You can choose to download the questions and responses for later use.<br>" +
        "14. You can ask for a private meeting with a professional coach who will go over your assessment (Fee Required).<br>" +
        "15. You can contact us or provide comments at " +
        "<a href='https://www.bestmanageditcompanies.com/contact/' target='_blank'>https://www.bestmanageditcompanies.com/contact/</a>" +
        "<br>" +
        "<br>" +
        "<h6 class='text-primary'>HOW TO INTERPRET THE RESULTS</h6>\n" +
        "The questions will help you gain some understanding of the questions you and your team should be asking. You'll have some things to think about just from this. The objectives of the questions are to create a profile or persona of your company's best business practices across fourteen key areas of importance. However, these 200 questions will give an excellent indication of how you are doing. Usually, there are hundreds of more questions to go into the fine nuances. Answering honestly and receiving a high score is a certain sign that you are doing extremely well. Simply consider what you can do to improve if you fall short in any areas. Contact www.channelpartneralliance.com if you require expert coaching or peer-group support.\n" +
        "\n" +
        "This test is free, and nobody will contact you unless you place in the top 50 and want to claim your award. You are not required to accept or obtain the honour. You won't be featured as an award winner if you decide to opt out. This evaluation is for your best practices self-review so you can compare them and choose how to move forward. Keep in mind that your score is compared to thousands of other assessments using artificial intelligence, and a qualified coach reviews the written questions to determine the final result. You can add your comments in the blank space provided after each section.\n" +
        "\n" +
        "This assessment is live 365 days a year so you have a lot of time to work through each section at your own pace. The ONLY deadline is if you wish to apply for the 50 Best Managed IT Companies Award. See details on www.bestmanageditcompanies.com ";*/

    onMounted(() => {
      getRegion(props.regionId);
    });
    async function getRegion(id) {
      apiService.get(`/get-region/${id}`).then(async function (resp) {
        if (resp.data.data.welcome_message) {
          welcome_message.value = await resp.data.data.welcome_message;
          await swalAlert();
        }
      });
    }
    function swalAlert() {
      const { value: accept } = Swal.fire({
        title: "<span class='text-success'>WELCOME!</span>",
        width: "80%",
        inputValue: 0,
        html: welcome_message.value,
        inputPlaceholder: "Don't show again",
        input: "checkbox",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value === 1) {
          hide(props.userId, result.value);
        }
      });
    }

    function hide(id, hide) {
      let object = {
        hide: hide,
      };
      apiService.patch(`${id}`, object);
    }
    return {
      region,
      welcome_message,
    };
  },
});
