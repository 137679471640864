
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import LoginSurvey from "@/components/swal/LoginSurvey.vue";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    LoginSurvey,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const notShow = ref<boolean>(true);
    const show = ref<boolean>(false);
    const id = ref<number>(0);
    const regionID = ref<number>(0);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(8).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      await store.dispatch(Actions.LOGIN, values);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      if (!error) {
        id.value = store.getters.currentUser.user.id;
        regionID.value = store.getters.currentUser.user.region;
        if (store.getters.currentUser.user.hide) {
          notShow.value = store.getters.currentUser.user.hide;
        } else {
          notShow.value = false;
        }
        await router.push({ name: "home" });
      } else {
        Swal.fire({
          text: typeof error === "string" ? error : error[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
if(submitButton.value){
  //Deactivate indicator
  submitButton.value?.removeAttribute("data-kt-indicator");
  // eslint-disable-next-line
  submitButton.value!.disabled = false;
}

    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      notShow,
      show,
      id,
      regionID,
    };
  },
});
